input.sex, input.payday, input.hag, input.ban, input.age, input.attendNum{
  position:static;
  width:50px;
}

input.pianoBan, input.otherBan, input.fee, input.apt, input.ajNum, input.attend {
  width:100px;
}

input.schoolLong, input.dob{
  width:130px;
}

/*   .name               
     .currentStudent          
     .pianoBan          
     .otherBan          
     .sex           
     .payday          
     .fee           
     .schoolLong          
     .hag           
     .ban           
     .age           
     .dob           
     .apt           
     .ajNum          
     .attend          
     .attendNum         
   */

/* .table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  padding: 0;
} */


