body {
  margin: 0;
  padding: 0 0 0 0;
  font-family: sans-serif;
}

.navbar {
  margin-bottom: 0px;
}

.clickable {
  cursor: pointer;
}

.display-linebreak {
  white-space: pre-line;
}
