body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font: 12pt "Tahoma";
}
main.container,
.container {
  padding-right: 0;
  padding-left: 0;
}
/* * {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
} */
.page2 {
  page-break-after: always;
  position: relative;
  width: 210mm;
  min-height: 297mm;
  /* border: 1px #d3d3d3 solid; */
  border-radius: 5px;
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); */
}
/* .subpage {
  padding: 1cm;
  border: 5px red solid;
  height: 257mm;
  outline: 2cm #ffeaea solid;
} */
.name1 {
  position: absolute;
  top: 205px;
  left: 220px;
}
.payday {
  position: absolute;
  top: 205px;
  left: 590px;
}
.school {
  position: absolute;
  top: 242px;
  left: 410px;
}
.hag_hbletter {
  position: absolute;
  top: 242px;
  left: 537px;
}
.age {
  position: absolute;
  top: 242px;
  left: 645px;
}
.male {
  position: absolute;
  top: 101px;
  left: 150px;
  color: blue;
}
.eval {
  position: absolute;
  top: 0px;
  left: 0px;
}
.eval1 {
  position: absolute;
  top: 231px;
  left: 500px;
}
.female {
  position: absolute;
  top: 101px;
  left: 165px;
  color: blue;
}
.birthyear {
  position: absolute;
  top: 242px;
  left: 200px;
  font-size: 16;
}
.birthmonth {
  position: absolute;
  top: 242px;
  left: 270px;
  font-size: 16;
}
.birthdate {
  position: absolute;
  top: 242px;
  left: 325px;
  font-size: 16;
}
.letter {
  position: absolute;
  top: 366px;
  left: 430px;
  white-space: pre-wrap;
  width: 18rem;
}
.pJin {
  position: absolute;
  top: 780px;
  left: 370px;
  font-size: 16;
  white-space: pre-wrap;
  width: 24rem;
}
.theory {
  position: absolute;
  top: 780px;
  left: 170px;
  font-size: 16;
  white-space: pre-wrap;
  width: 14rem;
}
.book {
  position: absolute;
  top: 975px;
  left: 330px;
  white-space: pre-wrap;
  font-size: 16;
}
.month {
  position: absolute;
  top: 68px;
  left: 85px;
  color: #006699;
}
div.card,
.card {
  border: 0;
  background-color: transparent;
}
div.card-body {
  padding: 2px;
}
